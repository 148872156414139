<template>
  <div class="white">
    <v-container grid-list-xs>
      <v-sheet height="100vh" color="transparent">
        <div>
          <v-text-field
            append-icon="mdi-magnify"
            class="my-3"
            color="indigo"
            v-model="word"
            @keyup="search"
          ></v-text-field>
          <div v-if="searched">
            <div
              class="py-2 flex"
              v-for="(v, i) in SP()"
              :key="i + 'a'"
              @click="findSearch(v)"
            >
              <v-icon color="indigo">mdi-clock-outline</v-icon>
              <h4 class="ml-4 mb-1 indigo--text source">{{ v }}</h4>
            </div>
          </div>
          <div
            class="my-2"
            v-for="(value, index) in searchProduct"
            :key="index"
          >
            <transition name="fade">
              <v-card
                class="mt-2 mb-5"
                elevation="0"
                @click="selectProduct(value)"
                :disabled="
                  value.total_left == 0 || value.out_of_stock ? true : false
                "
              >
                <v-divider class="my-2"></v-divider>
                <v-row class="my-2">
                  <v-col
                    cols="5"
                    lg="2"
                    class="pa-1 pl-4"
                    v-if="value.media_urls.length > 0"
                  >
                    <v-img
                      max-height="120"
                      max-width="140"
                      :src="image(value.media_urls[0])"
                    ></v-img>
                  </v-col>
                  <v-col cols="5" class="pl-3 flex flex-column">
                    <h4 class="nunito font-weight-bold">{{ value.name }}</h4>
                    <h5 class="lato red--text">Favourite</h5>
                    <span class="flex">
                      <h6 class="lato font-weight-bold mt-1">RM</h6>
                      <h4 class="lato font-weight-bold ml-1">
                        {{ value.unit_price }}
                      </h4>
                    </span>
                  </v-col>
                </v-row>
              </v-card>
            </transition>
          </div>
        </div>
      </v-sheet>
    </v-container>
  </div>
</template>
<script>
import { mapGetters } from "vuex";

export default {
  name: "search",
  data() {
    return {
      word: "",
      searched: [],
      searchProduct: [],
    };
  },

  computed: {
    ...mapGetters({
      product: "getProduct",
    }),
  },

  methods: {
    SP() {
      if (this.searched.length > 5) {
        return this.searched.slice(Math.max(this.searched.length - 5, 1));
      } else return this.searched;
    },

    search() {
      if (this.word == "") {
        this.searchProduct = [];
      } else {
        this.searchProduct = this.product.filter((prod) => {
          var query = this.word.toLowerCase();
          if (prod.name.toLowerCase().includes(query)) return true;
          return false;
        });
      }
    },

    findSearch(v) {
      this.word = v;
      this.search();
    },

    image(i) {
      if (i.s512) {
        return i.s512;
      } else
        return (
          "https://s3-ap-southeast-1.amazonaws.com/assets.getorders/" +
          i.original
        );
    },

    toProducts() {
      this.$router.push({
        name: "Page",
        params: { child: this.$route.params.child, page: "products" },
      });
    },

    selectProduct(i) {
      if (this.word !== "") {
        this.searched.push(this.word);
        localStorage.setItem("search", JSON.stringify(this.searched));
      }
      this.$store.dispatch("updateSP", i);
      this.$router.push({
        name: "Page",
        params: { child: this.$route.params.child, page: "product" },
      });
    },
  },

  beforeMount() {
    let a = localStorage.getItem("search");
    if (a) {
      this.searched = JSON.parse(a);
    }
  },
};
</script>
<style>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
